import React from "react";

import "react-select/dist/react-select.css";

class HomeCenterCard extends React.Component {
  constructor(props) {
    super(props);
    this.elementRef = React.createRef();
  }

  componentDidMount() {}

  // 监听 hoveredIndex 的变化
  componentDidUpdate(prevProps) {
    const { index, hoveredIndex, type } = this.props;
    if (prevProps.hoveredIndex !== hoveredIndex) {
      const hoverBlock = document.querySelector(`.home__center-${type}__hover`);
      if (index === hoveredIndex) {
        const top = this.getTop();
        const height = this.getHeight();
        if (hoveredIndex !== null) {
          hoverBlock.style.transition =
            hoverBlock.style.zIndex === "-1" ? "unset" : "all 0.3s";
          hoverBlock.style.height = `${height}px`;
          hoverBlock.style.zIndex = "1";
          hoverBlock.style.top = top + "px";
        } else {
          this.reset(hoverBlock);
        }
      } else {
        if (hoveredIndex === null) this.reset(hoverBlock);
      }
    }
  }

  getTop = () => {
    const child = this.elementRef.current;
    const parent = document.querySelector(
      `.home__center-${this.props.type}__list`
    );
    const childRect = child.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();
    const topRelativeToParent = childRect.top - parentRect.top;
    return topRelativeToParent;
  };

  getHeight = () => {
    if (this.elementRef.current) {
      const { height } = this.elementRef.current.getBoundingClientRect();
      return height;
    }
    return 0;
  };

  reset = hoverBlock => {
    hoverBlock.style.transition = "unset";
    hoverBlock.style.zIndex = "-1";
    hoverBlock.style.top = this.props.initTop + "px";
  };

  render() {
    const { style = {}, children, className = "" } = this.props;
    return (
      <div
        className={`home__center-card ${className}`}
        style={style}
        ref={this.elementRef}
      >
        {children}
      </div>
    );
  }
}

export default HomeCenterCard;
